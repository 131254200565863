import { Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Menu } from 'src/app/components/menu/menu.model';
import { Response } from 'src/app/models/response.model';
import { ProfileService } from '../profile/profile.service';
import { User } from '../user/user.model';
import { EsqueciSenhaComponent } from './esqueci-senha/esqueci-senha.component';
import { LoginService } from './login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  formGroup: FormGroup
  tip = 'Informe seu e-mail e senha'
  error = false
  public modalRef: BsModalRef;  
  @ViewChild('divRecaptcha', { static: false }) divRecaptcha!: ElementRef<HTMLDivElement>;  
  
  constructor(private loginService: LoginService,
              private router: Router,
              private profileService: ProfileService,
              private modalService: BsModalService,
              private ngZone: NgZone
    ) { 
      this.renderizarReCaptcha();
    }

  ngOnInit() {
    this.formGroup = new FormGroup({
      email: new FormControl('', {validators: [Validators.required]}),
      password: new FormControl('', {validators: [Validators.required]}),
      recaptcha: new FormControl('1234', [Validators.required]),
    })
    this.verificarToken()

    this.block()
    
  }

  block(){
    //Bloqueio por algum motivo. Ex: Falta de pagamento
    this.loginService.block()
        .subscribe((response: any)=>{
          if(response != null) this.popUpBloqueio()
        })
  }

  openModal() {
    this.modalRef = this.modalService.show(EsqueciSenhaComponent);
    this.modalRef.content.onClose.subscribe(result => {
      console.log('results', result);
    })
  }

  openChangePassword() {
    this.modalRef = this.modalService.show(AlterarSenhaComponent);
    this.modalRef.content.onClose.subscribe(result => {
      console.log('results', result);
    })
  }


  verificarToken(){
    const verificar = async () => {
      if( localStorage.getItem('token') ){
          await this.setMenus()
          this.router.navigate([`/${localStorage.getItem('mainUrl')}`])  
      }
    }
    verificar()
  }

  auth(){
    this.loginService
        .auth(this.formGroup.value)
        .subscribe( (response: Response<User>)=>{
            if(response.status){
              
              localStorage.clear()
              localStorage.setItem('token', response.token)
              localStorage.setItem('id', String(response.data.id))
              if( response.data.changePassword ){
                this.openChangePassword()
              }else{
                localStorage.setItem('name', response.data.name)
                localStorage.setItem('group', response.data._profile.description)
                localStorage.setItem('profile', String(response.data.idProfile))
                localStorage.setItem('mainUrl', response.data._profile.mainUrl)
                localStorage.setItem('email', response.data.email)
                this.setMenus().then(()=>{
                  this.router.navigate([`/${response.data._profile.mainUrl}`])
                })
                
              }
            }else{
              this.error = true
              this.tip = response.msg
            }
        })
  }

  setMenus(){
    return new Promise((resolve, reject)=>{
      this.profileService
          .getMenu()
          .subscribe((menus: Menu[])=>{
            localStorage.setItem('menu', JSON.stringify(menus))
            this.loginService.notify()
            resolve(true)
          })
    })
  }

  get grecaptcha(): any {
    const w = window as any;
    return w['grecaptcha'];
  }

  renderizarReCaptcha() {
    this.ngZone.runOutsideAngular(() => {
      if (!this.grecaptcha || !this.divRecaptcha) {
        setTimeout(() => {
          this.renderizarReCaptcha();
        }, 500);

        return;
      }

      const idElemento = document.getElementById('recaptcha');

      this.grecaptcha.render(idElemento, {
        sitekey: environment.siteKey,
        callback: (response: string) => {
          this.ngZone.run(() => {
            this.formGroup.get('recaptcha').setValue(response);
          });
        }
      })
    })
  }

  popUpBloqueio(){
    Swal.fire({      
      icon: 'error',
      title: 'Oops...',
      text: 'Entre em contato com a administração do sistema para mais detalhes',
      //footer: '<a href="">Brito Software</a>',
      showConfirmButton: false, //hide OK button
      allowOutsideClick: false,
    })
  }
 

}
