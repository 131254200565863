
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Commission } from 'src/app/pages/commission/commission.model';
import { FormService } from '../../../form.service';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-modal-budget',
  templateUrl: './modal-budget.component.html',
  styleUrls: ['./modal-budget.component.css']
})
export class ModalBudgetComponent implements OnInit {
 
  @Input() dados: any[]
  @Input() idx: number
  @Output() closeModal  = new EventEmitter()
  msgError: string
  formGroup: FormGroup
  optionChoose: string = ""
  constructor(private store: Store<any>) { }

  ngOnInit() {
    
  }  
  actionCloseModal() {
    this.closeModal.emit(this.dados)
  }

  subTotal(){
    return this.dados ? this.dados.map(d =>  Number(d.value)).reduce((prev, val)=> (prev + val),0 ) : 0
  }

  addAnother(){
    const obj = {description: '', value: 0, fixed: false, order: this.idx}
    this.dados.push( obj )
    //this.formService.addItemBudget(obj)
  }

  removeCurrent(index: number){
    this.dados.splice(index, 1)
    //this.formService.removeItemBudget(index)
  }
}
