import { createAction, props } from "@ngrx/store";

import { OrderState } from "./order.state";
import { User } from "src/app/pages/user/user.model";
import { Order } from "src/app/pages/order/order.model";

const loadOrder = createAction('[Order] Load order', props<Order>())
const loadPartner = createAction('[Order] Load partner', props<User>())


export const orderActions = {
    loadOrder,
    loadPartner
}