import { createReducer, on } from "@ngrx/store";
import { OrderState } from "./order.state";
import { orderActions } from "./order.action";
import { Order } from "src/app/pages/order/order.model";

const initialState: OrderState  = {
    order: null,
    partner: null
}

export const orderReducer = createReducer(
    initialState,
    on(orderActions.loadOrder, (currentState, action: any) => {
        return {
            ...currentState,
            order: action
        }
    }),
    on(orderActions.loadPartner, (currentState, action: any) => {
        return {
            ...currentState,
            partner: action
        }
    })
)