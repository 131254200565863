import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from './shared/shared.modules';
import { HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { EsqueciSenhaComponent } from './pages/login/esqueci-senha/esqueci-senha.component';
import { AlterarSenhaComponent } from './pages/login/alterar-senha/alterar-senha.component';
import { LoginComponent } from './pages/login/login.component';
import { ModalServiceComponent } from './pages/order/order-form/tab-property/modal-service/modal-service.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalBudgetComponent } from './pages/order/order-form/tab-invoicing/modal-budget/modal-budget.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { appReducers } from './redux/app.reducers';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    NotFoundComponent,
    LoginComponent,
    EsqueciSenhaComponent,
    AlterarSenhaComponent,
    ModalServiceComponent,
    ModalBudgetComponent
  ],
  imports: [
    BrowserModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    StoreModule.forRoot(appReducers)
  ],
  entryComponents: [
    EsqueciSenhaComponent,
    AlterarSenhaComponent,
    ModalServiceComponent,
    ModalBudgetComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
