import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paginate } from 'src/app/models/paginate.model';
import { environment } from 'src/environments/environment';
import { User } from './user.model';
import { Agency } from '../agency/agency.model';
import { Article } from '../article/article.model';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  private api = environment.host
  constructor(private http: HttpClient) {
    this.api = `${this.api}/api/user`
  }

  findAll(name: string, page: number, limit: number): Observable<Paginate<User>>{
    return this.http.patch<Paginate<User>>(this.api, {name, not: 0, page, limit})
  }

  findAllActive(){
    return this.http.get(`${this.api}/user-active/find`)
  }

  findUserPrice(id: number){
    return this.http.get<Agency[]>(`${this.api}/user-price/${id}`)
  }

  save(data: User){
    return this.http.post(this.api, data)
  }

  findById(id:number): Observable<User>{
    return this.http.get<User>(`${this.api}/${id}`)
  }

  findPriceByUser(id:number): Observable<Article[]>{
    return this.http.get<Article[]>(`${this.api}/user-price/${id}`)
  }

  savePriceByUser(info: any) {
    return this.http.post(`${this.api}/user-price`, info)
  }

  updatePriceUser(id: number, data: any){
    return this.http.put(`${this.api}/user-price/${id}`, data)
  }

  update(id: number, data: User){
    return this.http.put(`${this.api}/${id}`, data)
  }
  
  updateEmail(email: string, data: User){
    return this.http.put(`${this.api}/email/${email}`, data)
  }

  remove(id: number){
    return this.http.delete(`${this.api}/${id}`)
  }

  findProfile(id: number){
    return this.http.get(`${this.api}/profile/${id}`)
  }

}
